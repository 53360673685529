import React, {useEffect, useState} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import instance from "../../components/api/httpclient";
import { navigate, FormattedMessage } from "gatsby-plugin-intl"
import {useIntl} from "react-intl";
import {Modal} from "react-bootstrap";

const AccountActivated = () => {
  const intl = useIntl();
  const [email, setEmail] = useState( null);
  const [token, setToken] = useState( null);
  const [textTitle, setTextTitle] = useState( "1_1_2_login_activate_ok.title");
  const [textDescription1, setTextDescription1] = useState( "1_1_2_login_activate_ok.description1");
  const [textDescription2, setTextDescription2] = useState( null);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    let paramToken = '';

    if (typeof window !== 'undefined') {
      const getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
      };

      paramToken = getUrlParameter('token');

      setToken(paramToken);
      
      localStorage.setItem('token', 'Bearer ' +token);
      if (null !== token) {
        instance.get(`/confirm-mail/` + token)
          .then(function (response) {
            setTextDescription2("1_1_2_login_activate_ok.description2");
            console.log(response);
          }).catch((error) => {
          if (error.response.status === 400) {
            setTextTitle("1_1_2_login_activate_link_expired.title");
            setTextDescription1("1_1_2_login_activate_link_expired.description");
          }
        });
      }
    }

    if (typeof window !== 'undefined') {


    }
  },[token]);

  const handleClick = (event) => {
    instance.get("/me",
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
        }
      })
      .then((res) => {
          console.log('response', res)
          if (res.status === 200) {
            if (typeof window !== 'undefined') {
              localStorage.setItem('currentUser', JSON.stringify(res.data.user));
            }
            navigate('/sessions/onboarding-start');
          }
          else if (res.status === 401) {
            navigate('/home')
          }
        }
      )
    event.preventDefault();
  }

  const handleClose = () => {
    setModalShow(false);
  };

  const handleShow = () => {
    setModalShow(true);
  };

  const resendMail = (event) => {
    instance.post(`/resend-confirm-mail/` + token,
      {
        lang: intl.locale
      })
      .then(function (response) {
        setEmail(response.email);
      });

    if (event) {
      handleShow();
      event.preventDefault();
    }
  }

  const description2 = () => {
    if (null !== textDescription2) {
      return (
        <FormattedMessage id={textDescription2} />
      )
    }
  }

  const callToAction = () => {
    if (null !== textDescription2) {
      return (
        <div className="">
          <div className="button red mx-auto" onClick={(event) => handleClick(event)}><FormattedMessage
            id="generic.nav_buttons.lets_go_button"/></div>
        </div>
      );
    } else {
      return (
        <div className="">
          <div className="button red mx-auto" onClick={(event) => resendMail(event)}><FormattedMessage
            id="generic.forms.form_buttons.send_new_link"/></div>
        </div>
      );
    }
  }

  const icon = () => {
    if (null !== textDescription2) {
      return (
        <span className="icon-check fs-40 green"></span>
      )
    } else {
      return(
      <span className="icon-close fs-40"></span>
      );
    }
  }

  return (
    <Layout>

      <SEO title={intl.formatMessage({ id: "pages_meta_title.sessions.account-activated" })} />

      <div className="white-container mb-5">


        <div className="text-center mb-4">

          {icon()}

        </div>

        <h1 className="text-center"><FormattedMessage id={textTitle} /></h1>

        <p className="text-center mb-4"><FormattedMessage id={textDescription1} /><br />
          {description2()}
        </p>

        {callToAction()}

      </div>

      <Modal size="md" show={modalShow} onHide={() => handleClose()} animation={false}>
        <Modal.Header className="justify-content-center">
          <h2 className="modal-title text-uppercase flex-grow-1 text-center" id="exampleModalCenterTitle"><FormattedMessage id="1_1_2_login_sign_up_step2_please_confirm.title" /></h2>
          <button type="button" className="close" onClick={() => handleClose()}>
            <span aria-hidden="true"><span className="icon-close"></span></span>
          </button>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p><FormattedMessage id="1_1_2_login_sign_up_step2_please_confirm.description" values={{ email : email ? email : "" }}/></p>
        </Modal.Body>
      </Modal>
    </Layout>
  )
};

export default AccountActivated
